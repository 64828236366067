<template>
  <div class="w3-content w3-justify w3-text-grey w3-padding-64" id="about">
    <h2 class="w3-text-light-grey">Impressum</h2>
    <hr style="width:10em" class="w3-opacity">

    <h2 id="m56">Kontaktmöglichkeiten</h2>
    <div>
      <transition-group name="list-complete" tag="p">
        <span v-for="item in items.splice(0,14)" :key="item.id" class="list-complete-item">
          {{ item }}
        </span><br>
        <span v-for="item in items.splice(0,19)" :key="item.id" class="list-complete-item">
          {{ item }}
        </span><br>
        <span v-for="item in items.splice(0,12)" :key="item.id" class="list-complete-item">
          {{ item }}
        </span><br>
        <span v-for="item in items" :key="item.id" class="list-complete-item">
          {{ item }}
        </span>
      </transition-group>
      <button class="w3-button w3-dark-grey w3-padding-large" @click="shuffling">Anzeigen</button>
    </div>
    <br><br><br>
    <rechte></rechte>
  </div>
</template>

<script>
import RechteText from "@/components/RechteText";

'N D1 grm4e2Evl3mhiS2mar  @nnronlds4aordeadeihecban.o iMti h '

const info = [' ', '4', 'a', 'i', 't', 'M', ' ', ' ', 'o', '.', 'n', 'a', 'a', '7', 'e', 'h', 'i', 'e', 'd', 'a', 'e',
  'd', 'r', 'o', 'a', ' ', 's', 'ß', 'l', 'n', 'o', 'r', 'n', 'n', '@', '', ' ', 'r', ' ', 'm', '2', 'S', 'i', 'h',
  'm', '3', 't', 'v', 'S', '2', 'e', '4', 'm', 'r', 'g', ' ', '1', 'D', '', 'N'];
var code = [4, 28, 29, 48, 15, 5, 26, 13, 6, 57, 12, 10, 23, 27, 18, 7, 1,
  43, 58, 41, 25, 46, 56, 16, 45, 30, 3, 24, 2, 51, 54, 40, 11, 49,
  50, 32, 20, 8, 31, 53, 36, 14, 52, 55, 9, 37, 22, 17, 21, 33, 59,
  35, 47, 19, 42, 38, 34, 39, 44, 0];

export default {
  name: 'Impressum',
  data() {
    return {
      items: info.slice(),
      msg: "Hi"
    }
  },
  components: {
    'rechte': RechteText
  },
  methods: {
    shuffling() {
      let info_reorderd = [];
      for (let i = 0; i < 60; i++) {
        info_reorderd[code[i]] = info[i];
      }
      this.$data.items = info_reorderd;
    }

  }

}
</script>

<style scoped>
.list-complete-item {
  transition: all 1s ease;
  display: inline-block;
  min-width: 4px;
}

</style>
