<template>
  <a class="skip-link" href="#skip2main">Skip to main</a>
  <!-- Icon Bar (Sidebar - hidden on small screens) -->
  <nav class="w3-sidebar w3-bar-block w3-small w3-hide-small w3-center">
    <!-- Avatar image in top left corner -->
    <!--<img src="logos/nimohr_v1.jpg"> -->
    <img alt="Nimohr-Logo" :src="require('@/assets/nimohr.png')" style="width:100%">

    <router-link class="w3-bar-item w3-button w3-padding-large w3-black white-filter"
                 :to="{ name: 'Nimohr' }">

      <i class="material-icons-outlined md-36 active-green">home</i>
      <p>Nimohr</p>
    </router-link>

    <router-link class="w3-bar-item w3-button w3-padding-large w3-black white-filter"
                 :to="{ name: 'About' }">
      <!--<img :src="require('@/assets/icons/perm_identity-18dp.svg')" id="logos">-->
      <i class="material-icons md-36 active-blue">person_outline</i>
      <p>Über mich</p>
    </router-link>

    <router-link class="w3-bar-item w3-button w3-padding-large w3-black white-filter"
                 :to="{ name: 'Sport' }">
      <!--<img src="logos/Star_rot_2016_256.png" id="logos">-->
      <i class="material-icons-outlined md-36 active-red">star_rate</i>
      <p>Sport</p>
    </router-link>

    <router-link class="w3-bar-item w3-button w3-padding-large w3-black white-filter"
                 :to="{ name: 'Social' }">
      <!-- <img src="logos/IG_Glyph_Fill.png" id="logos"> -->
      <img alt="" :src="require('@/assets/icons/IG_Glyph_Fill.webp')" id="logos">
      <p>Social</p>
    </router-link>

    <router-link class="w3-bar-item w3-button w3-padding-large w3-black white-filter"
                 :to="{ name: 'Impressum' }">
      <i class="material-icons md-36 active-yellow">mail_outline</i>
      <p>Kontakt</p>
    </router-link>

  </nav>

  <!-- Navbar on small screens (Hidden on medium and large screens) -->
  <div class="w3-top w3-hide-large w3-hide-medium" id="myNavbar">
    <div class="w3-bar w3-black w3-opacity w3-hover-opacity-off w3-center w3-small">
      <router-link class="w3-bar-item w3-button nav-button"
                   :to="{ name: 'Nimohr' }">
        <p>Nimohr</p>
      </router-link>
      <router-link class="w3-bar-item w3-button nav-button"
                   :to="{ name: 'About' }">
        <p>Über</p>
      </router-link>
      <router-link class="w3-bar-item w3-button nav-button"
                   :to="{ name: 'Sport' }">
        <p>Sport</p>
      </router-link>
      <router-link class="w3-bar-item w3-button nav-button"
                   :to="{ name: 'Social' }">
        <p>Social</p>
      </router-link>
      <router-link class="w3-bar-item w3-button nav-button"
                   :to="{ name: 'Impressum' }">
        <p>Kontakt</p>
      </router-link>
      <!-- <a href="#contact" class="w3-bar-item w3-button" style="width:25% !important">CONTACT</a>-->
    </div>
  </div>


  <div class="w3-padding-large" id="main">
    <div id="app">
      <main id="skip2main">
        <router-view v-slot="{ Component }">
          <transition name="mode-fade" mode="out-in">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </transition>
        </router-view>
      </main>
    </div>
  </div>
  <!-- <img alt="Vue logo" src="./assets/logo.png">  -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
export default {name: 'App'}

/*export default {
  name: 'App',
  components: {
    Nimohr
  }
}*/
</script>

<style>
@import './assets/w3.css';
@import './assets/material_icons_outlined.css';

#demo {
  position: relative;
}

button {
  position: absolute;
}

.mode-fade-enter-active {
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  transition-property: height, opacity, transform;
  //transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.mode-fade-enter-from {
  transform: translate(0em, 1em);
  opacity: 0;
}

.mode-fade-enter-to {
  transform: translate(0em, 0em);
  opacity: 1;
}

.mode-fade-leave-active {
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  transition-property: height, opacity, transform;
  overflow: hidden;
}

.mode-fade-leave {
  transform: translate(2em, 0em);
  opacity: 1;
}

.mode-fade-leave-to {
  transform: translate(0em, -1em);
  opacity: 0;
}

/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/

.w3-row-padding img {
  margin-bottom: 12px
}

/* Set the width of the sidebar to 120px */
.w3-sidebar {
  width: 120px;
  background: #000;
}

/* Add a left margin to the "page content" that matches the width of the sidebar (120px) */
#main {
  margin-left: 120px
}

/* Remove margins from "page content" on small screens */
@media only screen and (max-width: 600px) {
  #main {
    margin-left: 0
  }
}

.material-icons.md-36,
.material-icons-outlined.md-36 {
  font-size: 36px;
}

#logos {
  max-width: 100%;
  max-height: 36px;
}

.white-filter {
  filter: saturate(0%) brightness(4);
}

.white-filter:hover {
  filter: grayscale(0%);
}

.router-link-exact-active {
  background-color: #616161 !important;
  filter: saturate(100%) brightness(100%);
}

.nav-button {
  min-width: 20%;
}

.active-yellow {
  filter: invert(83%) sepia(26%) saturate(5138%) hue-rotate(14deg) brightness(102%) contrast(108%);
  color: #000;
}

.active-blue {
  filter: invert(73%) sepia(73%) saturate(8000%) hue-rotate(187deg) brightness(98%) contrast(102%);
  color: #000;
}

.active-green {
  filter: invert(52%) sepia(88%) saturate(984%) hue-rotate(58deg) brightness(104%) contrast(98%);
  color: #000;
}

.active-red {
  filter: invert(11%) sepia(81%) saturate(5552%) hue-rotate(358deg) brightness(113%) contrast(118%);
  color: #000;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000000;
  color: white;
  padding: 8px;
  z-index: 100;
}

.skip-link:focus {
  top: 0;
}

@media (max-width: 600px) {
  div.data-tool-tip {
    position: relative;
    content: attr(data-tool-tip);
    max-width: 80%;
  }

  div.data-tool-tip:before {
    position: relative;
    content: attr(data-tool-tip);
    max-width: 80%;
  }
}

</style>
