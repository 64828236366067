<template>
  <div class="w3-content w3-justify w3-text-grey w3-padding-64" id="about">
    <h2 class="w3-text-light-grey">Socialmedia</h2>
    <hr style="width:11em" class="w3-opacity">
    <p> Instagram <a href="https://www.instagram.com/nimohr/" target="_blank" class="w3-hover-text-green">@nimohr</a>

    </p>

    <!-- Grid for photos -->
    <div class="w3-row-padding" style="margin:0 -16px">
      <div class="w3-half">
        <img src="images/2019-01-05_22-44-31_UTC.jpg" style="width:100%">
        <img src="images/2019-01-02_21-57-16_UTC.jpg" style="width:100%">
        <!-- <img src="images/2017-03-29_16-46-36_UTC.jpg" style="width:100%"> -->
      </div>

      <div class="w3-half">
        <img src="images/2017-06-13_21-06-43_UTC.jpg" style="width:100%">
        <img src="images/2017-04-16_23-26-29_UTC.jpg" style="width:100%">
        <!-- <img src="images/2017-03-27_16-44-56_UTC.jpg" style="width:100%"> -->
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'Social',
  data () {
    return {
      msg: 'About Nils'
    }
  }
}
</script>

<style scoped>
</style>
