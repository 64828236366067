import { createWebHistory, createRouter } from "vue-router";
import Nimohr from "@/components/Nimohr.vue";
import About from "@/components/About.vue";
import Sport from "@/components/Sport.vue";
import Social from "@/components/Social.vue";
import Impressum from "@/components/Impressum.vue";
import NotFound from "@/components/404.vue";

const routes = [
  {
    path: "/",
    name: "Nimohr",
    component: Nimohr,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/sport",
    name: "Sport",
    component: Sport,
  },
  {
    path: "/social",
    name: "Social",
    component: Social,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  }
];

const router = createRouter({
  history: createWebHistory(),
  mode: 'hash',
  routes,
});

export default router;