<template>
  <div class="w3-content w3-justify w3-text-grey w3-padding-64" id="about">
    <h2 class="w3-text-light-grey">Iron System® Instructor</h2>
    <hr style="width:22em" class="w3-opacity">
    <p>
        2011 habe ich als Teilnehmer im lokalen Sportverein, TuS Schwinde, das Langhanteltrainig im Gruppenkurs angefangen.
        Durch den Begin des Informatikstudiums habe ich zum Ausgleich abends mehr Sport betrieben.
        Im Jahr 2017 habe ich dann an ein paar Wochenenden die Ausbildung für das Iron System® gemeistert.
        Seitdem leite ich im Sportverein die Langhantel-Gruppenkurse als Instructor.
    </p>


    <h3 class="w3-padding-16 w3-text-light-grey">Ausbildung</h3>

    <div id="content">
      <ul class="timeline">
        <li class="schooling" data-date="2017">
          <h3>HOT IRON® Instructor</h3>
          <p>Ort: Ahrensburg</p>
          <p>Themen: Trainingslehre, Anatomie, Konzeptspezifische Inhalte HOT IRON®, Allgemeines Grundlagen Know-How</p>
        </li>
        <li class="schooling" data-date="2018">
          <h3>HOT IRON® Update</h3>
          <p>Ort: Hamburg</p>
          <p>Themen: HOT IRON® CROSS, Periodisierung und Körperfettreduktion, Physiologie des Trainings, Besonderheiten beim Hypertrophietraining</p>
        </li>

        <li class="event" data-date="2019">
          <h3>Releaseparty HOT IRON®</h3>
          <p>Ort: Hamburg</p>
        </li>

        <li class="schooling" data-date="2019">
          <h3>Professional Training "Fakten & Technik"</h3>
          <p>Ort: Wunstorf</p>
          <p>Themen: Instruktionen und korrektes Feedback, Lernmethoden und deren Risiken, Motorische Lernprozesse</p>
        </li>


        <li class="event" data-date="2019">
          <h3>Releaseparty HOT IRON®</h3>
          <p>Ort: Hamburg</p>
        </li>

        <li class="event" data-date="2019">
          <h3>Releaseparty HOT IRON®</h3>
          <p>Ort: Hamburg</p>
        </li>

        <li class="schooling" data-date="2020">
          <h3>Korce® Instructor</h3>
          <p>Ort: Hamburg</p>
          <p></p>
        </li>

        <li class="schooling" data-date="2021">
          <h3>Professional Training "Gesundheitliche Einschränkungen"</h3>
          <p>Ort: Hamburg</p>
          <p>Themen: Handgelenk und Schulter und deren Grundproblemaktiken
          </p>
        </li>

        <li class="schooling" data-date="2022">
          <h3>Professional Training "Krafttraining als Mobility-Tool"</h3>
          <p>Ort: Hamburg</p>
          <p>Themen: Verbesserung der Beweglichkeit bei den Kraftübungen
          </p>
        </li>

        <li class="schooling" data-date="2024">
          <h3>Professional Training "HOT IRON® - Entert(r)ainment"</h3>
          <p>Ort: Hamburg</p>
          <p>Themen: Performance - Grundlagen, Tools und Methoden
          </p>
        </li>

        <li class="event" data-date="2024">
          <h3>Releaseparty Korce®</h3>
          <p>Ort: Hamburg</p>
        </li>

        <li class="event" data-date="2024">
          <h3>Professional Training "HOT IRON® - Entert(r)ainment"</h3>
          <p>Ort: Hamburg</p>
          <p>Themen: Der Instructor als Kommunikations-Talent
          </p>
        </li>

        <li class="event" data-date="2024">
          <h3>Online-Schulung "Spezielle Zielgruppen"</h3>
          <p>Ort: E-Learning</p>
          <p>Themen: Zielgruppen Kids & Jugendliche, Best Ager, Schwangere und Frauen nach der Geburt
          </p>
        </li>
        <li class="event" data-date="2024">
          <h3>Online-Schulung "Gesundheitliche Einschränkungen:
Sprunggelenk, Kniegelenk und Hüftgelenk"</h3>
          <p>Ort: E-Learning</p>
          <p>Themen: Hintergrundwissen als Basis für den Umgang mit Teilnehmern mit gesundheitlichen Einschränkungen
          </p>
        </li>


      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Sport',
  data () {
    return {
      msg: 'Hier soll eine Sport Infoseite stehen'
    }
  }
}
</script>

<style scoped>
strong {
  font-weight: 600;
}
/* Timeline */
.timeline {
  border-left: 4px solid #AAAAAA;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0px auto auto 11em;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.1em;
  padding: 50px;
  list-style: none;
  text-align: left;
  font-weight: 100;
  max-width: 60%;
}
@media only screen and (max-width: 600px) {.timeline {
  margin: 0px auto auto 6em;
  max-width: 100%;
}}

.timeline h1,
.timeline h2,
.timeline h3 {
  letter-spacing: 1.5px;
  font-weight: 100;
  font-size: 1.4em;
  line-height: 1.2em;
}
.timeline .schooling,
.timeline .event{
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  padding-bottom: 15px;
  margin-bottom: 25px;
  position: relative;
}
.timeline .schooling:last-of-type,
.timeline .event:last-of-type{
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}
.timeline .schooling:before,
.timeline .schooling:after,
.timeline .event:before,
.timeline .event:after{
  position: absolute;
  display: block;
  top: 0;
}
.timeline .schooling:before {
  left: -217.5px;
  color: #AAAAAA;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  min-width: 120px;
  font-size: 3em;
}
@media only screen and (max-width: 600px) {.timeline .schooling:before {
  left: -9em;
  font-size: 1.5em;
}}

.timeline .schooling:after {
  box-shadow: 0 0 0 4px #FF1111;
  left: -57.85px;
  background: #FF1111;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  top: 5px;
}

.timeline .event:before {
  left: -217.5px;
  color: #AAAAAA;
  //content: attr(data-date);
  text-align: right;
  font-weight: 100;
  min-width: 120px;
  font-size: 2em;
}

.timeline .event:after {
  box-shadow: 0 0 0 2px #FF1111;
  left: -57.85px;
  background: #FF1111;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  top: 5px;
}




</style>
