<template>
  <div class="w3-content w3-justify w3-text-grey w3-padding-64" id="about">
    <h2 class="w3-text-light-grey">Nils Mohrmann</h2>
    <hr style="width:14em" class="w3-opacity">
    <p> Ich bin ein Informatiker aus der Region südlich von Hamburg. Zurzeit entwerfe und realisiere ich Prozesse und eine Infrastruktur in der GCP. In meinem Master habe ich mich viel mit der digitalen
      Signalverarbeitung, speziell der Sprachsignalverarbeitung beschäftigt.

    </p>


    <h3 class="w3-padding-16 w3-text-light-grey">Sprachen</h3>

    <div class="w3-button w3-dark-grey w3-padding-large clickable data-tool-tip"
        data-tooltip="Advanced to expert Level">
      Python
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large clickable data-tool-tip"
        data-tooltip="Main dialect: BigQuery">
      SQL
    </div>

    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      Java
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large clickable data-tool-tip"
         onclick="location.href='ICG/Island'"
         data-tooltip="Hier gibt es ein kleines Projekt mit WebGl">
      JavaScript
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large clickable data-tool-tip"
         onclick="location.href='https://tic80.com/play?cart=646'"
         data-tooltip="Ein kleines Puzzle Jump&Run Spiel, das bei einem Game Jam entstanden ist :D">
      Lua
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      C++
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      Prolog
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      Racket
    </div>

    <h3 class="w3-padding-16 w3-text-light-grey">Tools</h3>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      Airflow
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      Terraform
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large clickable data-tool-tip"
        data-tooltip="BigQuery, Composer, CloudRun, GCS, Pub/Sub">
      GCP
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      PyTorch
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      Tensorflow
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      Streamlit
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      Flask
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      Kafka
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      MongoDB
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      MySQL
    </div>
    <div class="w3-button w3-dark-grey w3-padding-large not-clickable">
      PostgreSQL
    </div>

    <h3 class="w3-padding-16 w3-text-light-grey">Studium</h3>
    <div id="content">
      <ul class="timeline">
        
        <li class="schooling" data-date="2023">
          <h3>Data Developer</h3>
          <p>bonprix Handelsgesellschaft mbH</p>
          <p>Design und Implementierung von einer Software- und Infrastrukturlandschaft</p>
        </li>

        <li class="schooling" data-date="2022">
          <h3>Junior Data Developer</h3>
          <p>bonprix Handelsgesellschaft mbH</p>
          <p>ETL Piplines</p>
        </li>

        <li class="schooling" data-date="2022">
          <h3>HiWi Informatik</h3>
          <p>Universität Hamburg</p>
          <p>Paper: On the Role of Spatial, Spectral, and Temporal Processing for DNN-based Non-linear Multi-channel Speech Enhancement <a href=https://arxiv.org/abs/2206.11181>[1]</a></p>
        </li>

        <li class="schooling" data-date="2021">
          <h3>M.Sc. Informatik</h3>
          <p>Universität Hamburg</p>
          <p>Masterarbeit: Comparative Study of Deep Neural Networks for Multichannel Speech Enhancement. Comparing Spatial, Spectral, and Temporal Processing.</p>
        </li>

        <li class="schooling" data-date="2020">
          <h3>Masterprojekt: Signal Processing</h3>
          <p>Universität Hamburg</p>
          <p>Einarbeitung und Implementierung des Papers <a href=https://arxiv.org/abs/1911.09649>[1].</a>
            Kurzfassung: Ortung von Geräuschquellen in visuellen Szenen mit einer zweiteiligen Netzwerkarchitektur und
            einem Semi-Supervised Lernansatz.</p>
        </li>

        <li class="schooling" data-date="2019">
          <h3>B.Sc. Informatik</h3>
          <p>Universität Hamburg</p>
          <p>Bachelorarbeit: Reduzierung des Eigengeräusches für eine robuste automatische Spracherkennung mit
            Robotern</p>
        </li>

      </ul>
    </div>


  </div>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      msg: 'About Nils'
    }
  }
}
</script>

<style scoped>
.not-clickable {
  pointer-events: none;
  margin-right: 1em;
  margin-bottom: 1em;
}

.clickable {
  margin-right: 1em;
  margin-bottom: 1em;
}

strong {
  font-weight: 600;
}

/* Timeline */
.timeline {
  border-left: 4px solid #AAAAAA;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0px auto auto 11em;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.1em;
  padding: 50px;
  list-style: none;
  text-align: left;
  font-weight: 100;
  max-width: 60%;
}

@media only screen and (max-width: 600px) {
  .timeline {
    margin: 0px auto auto 6em;
    max-width: 100%;
  }
}

.timeline h1,
.timeline h2,
.timeline h3 {
  letter-spacing: 1.5px;
  font-weight: 100;
  font-size: 1.4em;
  line-height: 1.2em;
}

.timeline .schooling,
.timeline .event {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  padding-bottom: 25px;
  margin-bottom: 75px;
  position: relative;
}

.timeline .schooling:last-of-type,
.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .schooling:before,
.timeline .schooling:after,
.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .schooling:before {
  left: -217.5px;
  top: -20px;
  color: #aaaaaa;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  min-width: 120px;
  max-width: 120px;
  font-size: 3em;
  line-height: 1.2em;
}

@media only screen and (max-width: 600px) {
  .timeline .schooling:before {
    left: -9em;
    top: 0px;
    font-size: 1.5em;
  }
}

.timeline .schooling:after {
  box-shadow: 0 0 0 4px #AAAAAA;
  left: -57.85px;
  background: #AAAAAA;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  top: 5px;
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;
  transition: all 0.15s ease;
  padding: 10px;
  color: #333;
  border-radius: 3px;

}

[data-tooltip]:hover:before {
  opacity: 1;
  background: #a1a1a1;
  margin-top: 40px;
  margin-left: -10px;
}

[data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
}


</style>
