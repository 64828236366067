<template>
  <header class="w3-container w3-padding-32 w3-center w3-black" id="home">
    <div class="w3-padding-24">
      <!-- <img class="main-logo" src="logos/nimohr_v1.jpg">-->
      <img class="main-logo" alt="Nimohr-Logo" :src="require('@/assets/nimohr.png')">
    </div>
    <div class="w3-center"><p class="nimohr">NiMohr</p></div>
    <p class="nimohr-small">Nils Mohrmann</p>
    <p>Informatiker und Fitnesstrainer</p>
    <!-- <img src="/w3images/man_smoke.jpg" alt="Nils" class="w3-image" width="992" height="1108"> -->
  </header>
</template>

<script>
export default {
  name: 'Nimohr',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.vertical-small {
  transform: rotate(270deg);
  font-size: 25%;
}
.vertical-small2 {
  font-weight: 100;
}

.nimohr {
  font-size: 64px;
  margin:  0px auto -10px auto;
  padding-bottom: 0px;
  border-bottom: 2px ridge rgba(255, 255, 255, 0.1);
  width: min-content;
  line-height: 1.1em;
}

.nimohr-small {
  font-size: 29px;
  margin-top: 10px;
}

.main-logo{
  padding-top: 40px;
  width: 50%;
  max-width: 400px;
}

</style>
